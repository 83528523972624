import type { PeriodTypeOption } from './TypeOption'
import { PeriodType } from './Type'
import { CognitoBase } from '~cognito/models/Cognito/Base'
import { CognitoTime } from '~cognito/models/Cognito/Time'

class PeriodEvent extends CognitoBase {
  type: PeriodType
  date: string
  type_id: number
  option?: PeriodTypeOption
  option_id?: number
  value?: number
  note?: string

  baseurl() {
    return '/api/v1/period/event'
  }

  constructor(source?: Partial<PeriodEvent>) {
    super()
    this.type = new PeriodType()
    this.date = ''
    this.type_id = 0
    Object.assign(this, source)
  }
}

export { PeriodEvent }
