<template>
  <div class="mx-auto max-w-lg px-4 py-6">
    <div class="mb-4">
      <div class="grid grid-cols-2">
        <div>
          <span class="text-2xl font-bold">
            Period
          </span>
          <p class="text-sm">
            Tracking System
          </p>
        </div>
        <div class="w-full text-right">
          <cgn-button color-info @click="addModal">
            <i-heroicons-solid:plus />
          </cgn-button>
        </div>
      </div>
    </div>
    <div class="rounded-lg bg-white px-8 py-6 shadow-lg dark:bg-slate-800">
      <div>
        <header class="flex items-center justify-between border-b border-gray-200 px-6 py-4">
          <div class="text-base font-semibold leading-6 text-darkbg-100">
            <time :datetime="format(curMonth, 'yyyy-MM')">
              {{ format(curMonth, 'MMMM yyyy') }}
            </time>
          </div>
          <div class="flex items-center">
            <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <div class="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300" aria-hidden="true" />
              <button
                type="button"
                class="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 md:w-9 md:px-2 md:hover:bg-gray-50"
                @click="subMonth"
              >
                <span class="sr-only">Previous month</span>
                <i-heroicons-solid:chevron-left class="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                class="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 md:block"
                @click="goToday"
              >
                Today
              </button>
              <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                class="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 md:w-9 md:px-2 md:hover:bg-gray-50"
                @click="addMonth"
              >
                <span class="sr-only">Next month</span>
                <i-heroicons-solid:chevron-right class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>
        <div ref="calendarBody" class="">
          <div class="shadow ring-1 ring-black/5">
            <div class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700">
              <div class="bg-white py-2">
                S<span class="sr-only sm:not-sr-only">un</span>
              </div>
              <div class="bg-white py-2">
                M<span class="sr-only sm:not-sr-only">on</span>
              </div>
              <div class="bg-white py-2">
                T<span class="sr-only sm:not-sr-only">ue</span>
              </div>
              <div class="bg-white py-2">
                W<span class="sr-only sm:not-sr-only">ed</span>
              </div>
              <div class="bg-white py-2">
                T<span class="sr-only sm:not-sr-only">hu</span>
              </div>
              <div class="bg-white py-2">
                F<span class="sr-only sm:not-sr-only">ri</span>
              </div>
              <div class="bg-white py-2">
                S<span class="sr-only sm:not-sr-only">at</span>
              </div>
            </div>
            <div class="flex bg-gray-200 text-xs leading-6 text-gray-700">
              <div class="isolate grid w-full grid-cols-7 grid-rows-6 gap-px">
                <button
                  v-for="day in days"
                  :key="day.date"
                  :style="{ backgroundColor: day.backgroundColor }"
                  type="button"
                  class="relative flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10"
                  :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isToday && 'text-brand-500', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500']"
                  @click="selectDay(day.date)"
                >
                  <time :datetime="day.date" class="ml-auto" :class="[day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full', day.isSelected && day.isToday && 'bg-brand-500', day.isSelected && !day.isToday && 'bg-gray-900']">
                    {{ format(day.day, 'd') }}
                  </time>
                  <div class="absolute bottom-1 right-1 text-[9px] text-black">
                    {{ day.day_of_cycle }}
                  </div>
                  <span class="sr-only">{{ day.events.length }} events</span>
                  <span v-if="day.events.length > 0" class="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    <span v-for="event in day.events" :key="event.id" class="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" :style="{ backgroundColor: event.backgroundColor }" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="selectedDay" class="px-4 py-10 sm:px-6">
            <div class="text-xl">
              {{ format(selectedDay.day, 'do MMM') }}
            </div>
            <div v-if="selectedDay.events.length > 0" class="cursor-pointer">
              <ol class="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black/5">
                <li
                  v-for="event in selectedDay.events" :key="event.id" class="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
                  @click="openModal(event.id)"
                >
                  <div class="flex-auto text-gray-900">
                    <p class="font-semibold">
                      <i-heroicons-solid:chat-bubble-left v-if="event.periodEvent.note" class="inline-block" />
                      {{ event.title }}
                      <span v-if="event.periodEvent.value">
                        {{ event.periodEvent.value }}
                      </span>
                    </p>
                  </div>
                </li>
              </ol>
            </div>
            <div v-else>
              Nothing recorded
            </div>
          </div>
        </div>
      </div>
      <cgn-modal v-if="modal_event.id" v-model="event_popup">
        <template #clean-content>
          <div class="mt-2 text-blue-600">
            {{ format(parse(modal_event.date, 'yyyy-MM-dd', new Date()), 'd MMMM') }}
          </div>
          <div class="select-none text-lg font-medium leading-6 text-gray-200">
            {{ modal_event.type.name }}
          </div>
          <form>
            <cgn-form-input-text v-model="modal_event.date" type="date" />
            <cgn-form-input-text v-if="eventTypes.find(e => e.id == modal_event.type_id)?.track_numeric_values_over_time" v-model="modal_event.value" placeholder="Value" />
            <cgn-form-dropdown v-if="updateTypeOptions.length > 0" v-model="modal_event.option_id" :options="updateTypeOptions" />
            <cgn-form-input-textarea v-model="modal_event.note" placeholder="Note" />
          </form>
        </template>
        <template #button-footer>
          <div class="flex w-full justify-between border-t-2 border-gray-200 p-3">
            <cgn-button
              color-info @click="modal_event.save().then(() => {
                getEvents()
                useToastStore().addToast('Saved', 'success', 3000)
                event_popup = false
              })"
            >
              Change
            </cgn-button>
            <cgn-button
              color-danger @click="modal_event.delete().then(() => {
                getEvents()
                useToastStore().addToast('Deleted', 'success', 3000)
                event_popup = false
              })"
            >
              Delete
            </cgn-button>
          </div>
        </template>
      </cgn-modal>
      <cgn-modal v-model="add_modal_mode">
        <template #clean-content>
          <div class="select-none text-lg font-medium leading-6 text-gray-200">
            <form>
              <cgn-form-input-text v-model="newEvent.date" type="date" />
              <cgn-form-dropdown v-model="newEvent.type_id" :options="eventTypes" />
              <cgn-form-dropdown v-if="typeOptions.length > 0" v-model="newEvent.option_id" :options="typeOptions" />
              <cgn-form-input-text v-if="eventTypes.find(e => e.id == newEvent.type_id)?.track_numeric_values_over_time" v-model="newEvent.value" placeholder="Value" />
              <cgn-form-input-textarea v-model="newEvent.note" placeholder="Note" />
            </form>
          </div>
        </template>
        <template #button-footer>
          <div class="w-full border-t-2 border-gray-200 p-3">
            <cgn-button color-info @click="saveNew">
              Save
            </cgn-button>
          </div>
        </template>
      </cgn-modal>
    </div>
  </div>
</template>

<script setup lang="ts">
import { addDays, addMonths, format, formatISO, isAfter, isBefore, isSameDay, isSunday, parse, parseISO, previousSunday, startOfDay, startOfMonth, subMonths } from 'date-fns'
import { PeriodEvent } from '~/models/Period/Event'
import { PeriodType } from '~/models/Period/Type'
import { PeriodTypeOption } from '~/models/Period/TypeOption'
import { CognitoTime } from '~cognito/models/Cognito/Time'
import { $axios } from '~cognito/plugins/axios'

const calendarEvents = ref<{
  id: number
  title: string
  start: string
  end: string
  backgroundColor: string
  textColor: string
  event: PeriodEvent
}[]>([])

const calendarDates = ref<{
  date: string
  background: string
  day_of_cycle: number
}[]>([])

const newEvent = ref<PeriodEvent>(new PeriodEvent())
const modal_event = ref(new PeriodEvent())
const eventTypes = ref<PeriodType[]>([])
const eventTypeOptions = ref<PeriodTypeOption[]>([])

const typeOptions = computed(() => {
  return eventTypeOptions.value.filter(e => e.type_id === newEvent.value.type_id)
})
const updateTypeOptions = computed(() => {
  return eventTypeOptions.value.filter(e => e.type_id === modal_event.value?.type_id)
})

const curMonth = ref(startOfMonth(new Date()))
const selectedDate = ref('')
const event_popup = ref(false)
const add_modal_mode = ref(false)

const getEvents = async () => {
  const start = format(subMonths(curMonth.value, 1), 'yyyy-MM-dd')
  const end = format(addMonths(curMonth.value, 2), 'yyyy-MM-dd')
  const data = await $axios
    .get(`/api/v1/period/event/calendar?start=${start}&end=${end}`)
  calendarEvents.value = data.data.events
  calendarDates.value = data.data.dates
}

const saveNew = () => {
  new PeriodEvent(newEvent.value).save().then((data) => {
    add_modal_mode.value = false
    if (data.success) {
      getEvents()
      useToastStore().addToast('Saved', 'success', 3000)
    } else {
      useToastStore().addToast(data.error, 'fail', 5000)
    }
  })
}
const getTypes = async () => {
  const types = await (new PeriodType()).find_many({})
  eventTypes.value = types.mapped
  const options = await (new PeriodTypeOption()).find_many({})
  eventTypeOptions.value = options.mapped
}

const goToday = () => {
  curMonth.value = startOfMonth(new Date())
}
const addMonth = () => {
  curMonth.value = addMonths(curMonth.value, 1)
}
const subMonth = () => {
  curMonth.value = subMonths(curMonth.value, 1)
}

const showOnCalendar = (startTime: Date, endTime: Date, day: Date) => {
  const startDay = startOfDay(startTime)
  const endDay = startOfDay(endTime)

  if (isSameDay(startDay, day)) {
    return true
  }
  if (isSameDay(endDay, day)) {
    return true
  }
  if (isAfter(endDay, day) && isBefore(startDay, day)) {
    return true
  }
  return false
}

class EventData {
  id: number
  title: string
  timespan: string
  start: Date
  end: Date
  start_day: string
  end_day: string
  starts_today: boolean
  ends_today: boolean
  datetimeISO: string
  backgroundColor: string
  textColor: string
  periodEvent: PeriodEvent
  constructor(source?: Partial<EventData>) {
    this.id = 0
    this.title = ''
    this.timespan = ''
    this.start = new Date()
    this.end = new Date()
    this.start_day = ''
    this.end_day = ''
    this.starts_today = false
    this.ends_today = false
    this.datetimeISO = ''
    this.backgroundColor = ''
    this.textColor = ''
    this.periodEvent = new PeriodEvent()
    Object.assign(this, source)
  }
}

const days = computed<{
  date: string
  day: Date
  day_of_cycle: number
  isCurrentMonth: boolean
  isToday: boolean
  isSelected: boolean
  backgroundColor: string
  events: EventData[]
}[]>(() => {
  let calculateDate = curMonth.value
  const endDate = addMonths(calculateDate, 1)
  const currentMonthNumber = format(curMonth.value, 'MM')
  if (!isSunday(calculateDate)) {
    calculateDate = previousSunday(calculateDate)
  }
  const data = []
  while (isBefore(calculateDate, endDate)) {
    for (let i = 0; i < 42; i++) {
      // Calculate events for this day
      const todayEvents = calendarEvents.value.filter(e => showOnCalendar(parseISO(e.start), parseISO(e.end), calculateDate)).map((item) => {
        const start = parseISO(item.start)
        const end = parseISO(item.end)
        return new EventData({
          id: item.id,
          title: item.title,
          start,
          end,
          timespan: `${format(start, 'h:mmaaaaa')} - ${format(end, 'h:mmaaaaa')}`,
          start_day: format(start, 'dd MMM'),
          end_day: format(end, 'dd MMM'),
          starts_today: isSameDay(start, calculateDate),
          ends_today: isSameDay(end, calculateDate),
          datetimeISO: formatISO(start),
          backgroundColor: item.backgroundColor,
          textColor: item.textColor,
          periodEvent: item.event,
        })
      })
      const date = format(calculateDate, 'yyyy-MM-dd')
      data.push({
        date,
        day: calculateDate,
        day_of_cycle: calendarDates.value.find(e => e.date == date)?.day_of_cycle,
        isCurrentMonth: currentMonthNumber === format(calculateDate, 'MM'),
        isToday: isSameDay(new Date(), calculateDate),
        isSelected: format(calculateDate, 'yyyy-MM-dd') === selectedDate.value,
        backgroundColor: calendarDates.value.find(e => e.date == date)?.background,
        events: todayEvents,
      })
      calculateDate = addDays(calculateDate, 1)
    }
  }
  return data
})

watch(() => curMonth.value, getEvents)

const selectedDay = computed(() => {
  return days.value.find(day => day.date === selectedDate.value)
})

const selectDay = (date: string) => {
  selectedDate.value = date
}

const addModal = () => {
  add_modal_mode.value = true
  newEvent.value.type_id = 0
  newEvent.value.option_id = 0
  newEvent.value.date = (new CognitoTime(selectedDay.value?.day)).toDateString()
  newEvent.value.value = undefined
  newEvent.value.note = ''
}

const openModal = (id: number) => {
  new PeriodEvent().find_one({ url: String(id) })
    .then((data) => {
      modal_event.value = new PeriodEvent(data)
      event_popup.value = true
    })
}

const calendarBody = ref(null)
const { direction } = useSwipe(calendarBody)

watch (() => direction.value, () => {
  if (direction.value == 'left') {
    addMonth()
  }
  if (direction.value == 'right') {
    subMonth()
  }
})

onMounted(async () => {
  selectedDate.value = format(new Date(), 'yyyy-MM-dd')
  await getEvents()
  await getTypes()
})
</script>
