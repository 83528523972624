import { CognitoBase } from '~cognito/models/Cognito/Base'
import type { CognitoImage } from '~cognito/models/Cognito/Image'

class PeriodTypeOption extends CognitoBase {
  type_id: number
  name: string
  colour: string
  icon?: CognitoImage

  baseurl() {
    return '/api/v1/period/typeOption'
  }

  constructor(source?: Partial<PeriodTypeOption>) {
    super()
    this.type_id = 0
    this.name = ''
    this.colour = ''
    Object.assign(this, source)
  }
}

export { PeriodTypeOption }
