import { CognitoBase } from '~cognito/models/Cognito/Base'
import type { CognitoImage } from '~cognito/models/Cognito/Image'

class PeriodType extends CognitoBase {
  name: string
  colour: string
  icon?: CognitoImage
  is_period_start: boolean
  is_period_end: boolean
  track_numeric_values_over_time: boolean

  baseurl() {
    return '/api/v1/period/type'
  }

  constructor(source?: Partial<PeriodType>) {
    super()
    this.name = ''
    this.colour = ''
    this.is_period_start = false
    this.is_period_end = false
    this.track_numeric_values_over_time = false
    Object.assign(this, source)
  }
}

export { PeriodType }
